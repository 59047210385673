import { getCustomQueries } from '@/helpers/queryHelper';
import {
  ACTIVE_SUBJECT_ID,
  ADD_COURSE,
  ADD_COURSE_CLIENTS,
  ADD_COURSE_CLIENTS_FAILED,
  ADD_COURSE_CLIENTS_SUCCEEDED,
  ADD_COURSE_COMMENTS_FAILED,
  ADD_COURSE_COMMENTS_REQUEST,
  ADD_COURSE_COMMENTS_SUCCEEDED,
  ADD_COURSE_DOUBT_COMMENTS,
  ADD_COURSE_DOUBT_COMMENTS_FAILED,
  ADD_COURSE_DOUBT_COMMENTS_SUCCEEDED,
  ADD_COURSE_ENROLL,
  ADD_COURSE_ENROLL_FAILED,
  ADD_COURSE_ENROLL_SUCCEEDED,
  ADD_COURSE_ENROL_CONTENT,
  ADD_COURSE_ENROL_CONTENT_FAILED,
  ADD_COURSE_ENROL_CONTENT_SUCCEEDED,
  ADD_COURSE_FAILED,
  ADD_COURSE_LIVE_CLASS,
  ADD_COURSE_LIVE_CLASS_FAILED,
  ADD_COURSE_LIVE_CLASS_SUCCEEDED,
  ADD_COURSE_REVIEW,
  ADD_COURSE_REVIEW_FAILED,
  ADD_COURSE_REVIEW_SUCCEEDED,
  ADD_COURSE_SUCCEEDED,
  ADD_COURSE_TO_WISHLIST,
  ADD_COURSE_TO_WISHLIST_FAILED,
  ADD_COURSE_TO_WISHLIST_SUCCEEDED,
  ADD_NOTES,
  ADD_NOTES_FAILED,
  ADD_NOTES_SUCCEEDED,
  ADD_QUESTION_DOUBTS,
  ADD_QUESTION_DOUBTS_FAILED,
  ADD_QUESTION_DOUBTS_SUCCEEDED,
  ADD_QUESTION_DOUBT_COMMENT,
  ADD_QUESTION_DOUBT_COMMENT_FAILED,
  ADD_QUESTION_DOUBT_COMMENT_SUCCEEDED,
  CLEAR_COURSE_DATA,
  CLEAR_COURSE_ERROR,
  CLEAR_COURSE_QUERY,
  DELETE_COURSE,
  DELETE_COURSE_COMMENTS_FAILED,
  DELETE_COURSE_COMMENTS_REQUEST,
  DELETE_COURSE_COMMENTS_SUCCEEDED,
  DELETE_COURSE_DOUBT_COMMENTS,
  DELETE_COURSE_DOUBT_COMMENTS_FAILED,
  DELETE_COURSE_DOUBT_COMMENTS_SUCCEEDED,
  DELETE_COURSE_FAILED,
  DELETE_COURSE_LIVE_CLASS,
  DELETE_COURSE_LIVE_CLASS_FAILED,
  DELETE_COURSE_LIVE_CLASS_SUCCEEDED,
  DELETE_COURSE_REVIEW,
  DELETE_COURSE_REVIEW_FAILED,
  DELETE_COURSE_REVIEW_SUCCEEDED,
  DELETE_COURSE_SUCCEEDED,
  DELETE_NOTES,
  DELETE_NOTES_FAILED,
  DELETE_NOTES_SUCCEEDED,
  DELETE_QUESTION_DOUBTS,
  DELETE_QUESTION_DOUBTS_FAILED,
  DELETE_QUESTION_DOUBTS_SUCCEEDED,
  DELETE_QUESTION_DOUBT_COMMENT,
  DELETE_QUESTION_DOUBT_COMMENT_FAILED,
  DELETE_QUESTION_DOUBT_COMMENT_SUCCEEDED,
  DISLIKE_COURSE_REVIEW,
  DISLIKE_COURSE_REVIEW_FAILED,
  DISLIKE_COURSE_REVIEW_SUCCEEDED,
  EDIT_COURSE,
  EDIT_COURSE_CLIENTS,
  EDIT_COURSE_CLIENTS_FAILED,
  EDIT_COURSE_CLIENTS_SUCCEEDED,
  EDIT_COURSE_COMMENTS_FAILED,
  EDIT_COURSE_COMMENTS_REQUEST,
  EDIT_COURSE_COMMENTS_SUCCEEDED,
  EDIT_COURSE_DOUBT_COMMENTS,
  EDIT_COURSE_DOUBT_COMMENTS_FAILED,
  EDIT_COURSE_DOUBT_COMMENTS_SUCCEEDED,
  EDIT_COURSE_FAILED,
  EDIT_COURSE_LIVE_CLASS,
  EDIT_COURSE_LIVE_CLASS_FAILED,
  EDIT_COURSE_LIVE_CLASS_SUCCEEDED,
  EDIT_COURSE_REVIEW,
  EDIT_COURSE_REVIEW_FAILED,
  EDIT_COURSE_REVIEW_SUCCEEDED,
  EDIT_COURSE_SUCCEEDED,
  EDIT_NOTES,
  EDIT_NOTES_FAILED,
  EDIT_NOTES_SUCCEEDED,
  EDIT_QUESTION_DOUBTS,
  EDIT_QUESTION_DOUBTS_FAILED,
  EDIT_QUESTION_DOUBTS_SUCCEEDED,
  EDIT_QUESTION_DOUBT_COMMENT,
  EDIT_QUESTION_DOUBT_COMMENT_FAILED,
  EDIT_QUESTION_DOUBT_COMMENT_SUCCEEDED,
  EDIT_REQUESTED_COURSE_INQUIRY_STATUS,
  EDIT_REQUESTED_COURSE_INQUIRY_STATUS_FAILED,
  EDIT_REQUESTED_COURSE_INQUIRY_STATUS_SUCCEEDED,
  GET_COURSES,
  GET_COURSES_BY_PAGE,
  GET_COURSES_BY_PAGE_FAILED,
  GET_COURSES_BY_PAGE_SUCCEEDED,
  GET_COURSES_FAILED,
  GET_COURSES_SUCCEEDED,
  GET_COURSE_BY_SLUG,
  GET_COURSE_BY_SLUG_FAILED,
  GET_COURSE_BY_SLUG_SUCCEEDED,
  GET_COURSE_CATEGORY_LIST,
  GET_COURSE_CATEGORY_LIST_FAILED,
  GET_COURSE_CATEGORY_LIST_SUCCEEDED,
  GET_COURSE_CLIENT,
  GET_COURSE_CLIENTS,
  GET_COURSE_CLIENTS_FAILED,
  GET_COURSE_CLIENTS_SUCCEEDED,
  GET_COURSE_CLIENT_FAILED,
  GET_COURSE_CLIENT_SUCCEEDED,
  GET_COURSE_COMMENTS_FAILED,
  GET_COURSE_COMMENTS_REQUEST,
  GET_COURSE_COMMENTS_SUCCEEDED,
  GET_COURSE_DETAIL,
  GET_COURSE_DETAIL_FAILED,
  GET_COURSE_DETAIL_SUCCEEDED,
  GET_COURSE_DOUBTS,
  GET_COURSE_DOUBTS_FAILED,
  GET_COURSE_DOUBTS_SUCCEEDED,
  GET_COURSE_DOUBT_COMMENTS,
  GET_COURSE_DOUBT_COMMENTS_FAILED,
  GET_COURSE_DOUBT_COMMENTS_SUCCEEDED,
  GET_COURSE_ENROLL,
  GET_COURSE_ENROLL_FAILED,
  GET_COURSE_ENROLL_SUCCEEDED,
  GET_COURSE_ENROL_DETAIL,
  GET_COURSE_ENROL_DETAIL_FAILED,
  GET_COURSE_ENROL_DETAIL_SUCCEEDED,
  GET_COURSE_FEEDBACKS,
  GET_COURSE_FEEDBACKS_FAILED,
  GET_COURSE_FEEDBACKS_SUCCEEDED,
  GET_COURSE_FEEDBACK_BY_ID,
  GET_COURSE_FEEDBACK_BY_ID_FAILED,
  GET_COURSE_FEEDBACK_BY_ID_SUCCEEDED,
  GET_COURSE_FREE_TRIALS,
  GET_COURSE_FREE_TRIALS_FAILED,
  GET_COURSE_FREE_TRIALS_SUCCEEDED,
  GET_COURSE_LIST,
  GET_COURSE_LIST_FAILED,
  GET_COURSE_LIST_SUCCEEDED,
  GET_COURSE_LIVE_CLASS,
  GET_COURSE_LIVE_CLASS_FAILED,
  GET_COURSE_LIVE_CLASS_SUCCEEDED,
  GET_COURSE_MATERIAL,
  GET_COURSE_MATERIAL_BY_ID,
  GET_COURSE_MATERIAL_BY_ID_FAILED,
  GET_COURSE_MATERIAL_BY_ID_SUCCEEDED,
  GET_COURSE_MATERIAL_FAILED,
  GET_COURSE_MATERIAL_SUCCEEDED,
  GET_COURSE_REVIEW,
  GET_COURSE_REVIEW_BY_PAGE,
  GET_COURSE_REVIEW_BY_PAGE_FAILED,
  GET_COURSE_REVIEW_BY_PAGE_SUCCEEDED,
  GET_COURSE_REVIEW_FAILED,
  GET_COURSE_REVIEW_SUCCEEDED,
  GET_COURSE_VIDEO,
  GET_COURSE_VIDEO_FAILED,
  GET_COURSE_VIDEO_SUCCEEDED,
  GET_MOCK_TEST_CLIENT,
  GET_MOCK_TEST_CLIENT_FAILED,
  GET_MOCK_TEST_CLIENT_SUCCEEDED,
  GET_NOTES,
  GET_NOTES_FAILED,
  GET_NOTES_SUCCEEDED,
  GET_QUESTION_DOUBTS,
  GET_QUESTION_DOUBTS_BY_PAGE,
  GET_QUESTION_DOUBTS_BY_PAGE_FAILED,
  GET_QUESTION_DOUBTS_BY_PAGE_SUCCEEDED,
  GET_QUESTION_DOUBTS_DETAIL,
  GET_QUESTION_DOUBTS_DETAIL_FAILED,
  GET_QUESTION_DOUBTS_DETAIL_SUCCEEDED,
  GET_QUESTION_DOUBTS_FAILED,
  GET_QUESTION_DOUBTS_SUCCEEDED,
  GET_QUESTION_DOUBT_COMMENT,
  GET_QUESTION_DOUBT_COMMENT_BY_PAGE,
  GET_QUESTION_DOUBT_COMMENT_BY_PAGE_FAILED,
  GET_QUESTION_DOUBT_COMMENT_BY_PAGE_SUCCEEDED,
  GET_QUESTION_DOUBT_COMMENT_FAILED,
  GET_QUESTION_DOUBT_COMMENT_SUCCEEDED,
  GET_RELATED_COURSE,
  GET_RELATED_COURSE_FAILED,
  GET_RELATED_COURSE_SUCCEEDED,
  GET_REQUESTED_COURSE_INQUIRY,
  GET_REQUESTED_COURSE_INQUIRY_FAILED,
  GET_REQUESTED_COURSE_INQUIRY_SUCCEEDED,
  LIKE_COURSE_QUESTION,
  LIKE_COURSE_QUESTION_FAILED,
  LIKE_COURSE_QUESTION_SUCCEEDED,
  LIKE_COURSE_REVIEW,
  LIKE_COURSE_REVIEW_FAILED,
  LIKE_COURSE_REVIEW_SUCCEEDED,
  LIKE_QUESTION_DOUBT_COMMENT,
  LIKE_QUESTION_DOUBT_COMMENT_FAILED,
  LIKE_QUESTION_DOUBT_COMMENT_SUCCEEDED,
  SET_COURSE_DATA,
  SET_COURSE_EDIT_DATA,
  SET_COURSE_ENROLL_EDIT_DATA,
  SET_COURSE_ERROR_DATA,
  SET_COURSE_QUERY_DATA,
  UPDATE_COURSE_ENROL_DETAIL,
  UPDATE_COURSE_ENROL_DETAIL_FAILED,
  UPDATE_COURSE_ENROL_DETAIL_SUCCEEDED,
} from './index';

export const getCourses = (data = {}) => {
  let query = '';
  if (data) {
    Object.keys(data).map((each) => {
      query = `${query}&${each}=${data[each]}`;
    });
  }
  return {
    type: GET_COURSES,
    payload: {
      url: `course/?${query}`,
      onSuccess: GET_COURSES_SUCCEEDED,
      onFailure: GET_COURSES_FAILED,
    },
  };
};

export const getCoursesByPage = (data = {}) => {
  const query = getCustomQueries(data);
  return {
    type: GET_COURSES_BY_PAGE,
    payload: {
      url: `course-list/?${query}`,
      onSuccess: GET_COURSES_BY_PAGE_SUCCEEDED,
      onFailure: GET_COURSES_BY_PAGE_FAILED,
    },
  };
};

export const getCourseBySlug = (slug = {}) => {
  return {
    type: GET_COURSE_BY_SLUG,
    payload: {
      url: `course/${slug}/`,
      onSuccess: GET_COURSE_BY_SLUG_SUCCEEDED,
      onFailure: GET_COURSE_BY_SLUG_FAILED,
    },
  };
};

export const addCourseToWishList = (slug = {}) => {
  return {
    type: ADD_COURSE_TO_WISHLIST,
    payload: {
      url: `wishlist/?course=${slug}`,
      onSuccess: ADD_COURSE_TO_WISHLIST_SUCCEEDED,
      onFailure: ADD_COURSE_TO_WISHLIST_FAILED,
    },
  };
};

export const addCourse = (data = {}) => {
  return {
    type: ADD_COURSE,
    payload: {
      data,
      url: 'course/',
      onSuccess: ADD_COURSE_SUCCEEDED,
      onFailure: ADD_COURSE_FAILED,
    },
  };
};

export const editCourse = (data = {}, slug = {}) => {
  return {
    type: EDIT_COURSE,
    payload: {
      data,
      url: `course/${slug}/`,
      onSuccess: EDIT_COURSE_SUCCEEDED,
      onFailure: EDIT_COURSE_FAILED,
    },
  };
};

export const deleteCourse = (data = {}) => {
  return {
    type: DELETE_COURSE,
    payload: {
      url: `course/${data}/`,
      onSuccess: DELETE_COURSE_SUCCEEDED,
      onFailure: DELETE_COURSE_FAILED,
    },
  };
};

//landing course list

export const getCourseList = (data) => {
    const currency = window.localStorage.getItem('currency');
    const query = getCustomQueries(data);
    return {
      type: GET_COURSE_LIST,
      payload: {
        url: `course-list/?${query}&currency=${currency}`,
        onSuccess: GET_COURSE_LIST_SUCCEEDED,
        onFailure: GET_COURSE_LIST_FAILED,
      },
    };
};

export const getCourseCategoryList = () => {
  return {
    type: GET_COURSE_CATEGORY_LIST,
    payload: {
      url: 'course-category/',
      onSuccess: GET_COURSE_CATEGORY_LIST_SUCCEEDED,
      onFailure: GET_COURSE_CATEGORY_LIST_FAILED,
    },
  };
};

export const getCourseDetail = (slug = {}) => {
  const currency = window.localStorage.getItem('currency');
  return {
    type: GET_COURSE_DETAIL,
    payload: {
      url: `course-list/${slug}/?currency=${currency}`,
      onSuccess: GET_COURSE_DETAIL_SUCCEEDED,
      onFailure: GET_COURSE_DETAIL_FAILED,
    },
  };
};

export const getCourseEnrolDetail = (slug = {}) => {
  return {
    type: GET_COURSE_ENROL_DETAIL,
    payload: {
      url: `enrol/courses/${slug}/`,
      onSuccess: GET_COURSE_ENROL_DETAIL_SUCCEEDED,
      onFailure: GET_COURSE_ENROL_DETAIL_FAILED,
    },
  };
};

export const getCourseMaterial = (contentId = {}, course = {}) => {
  return {
    type: GET_COURSE_MATERIAL,
    payload: {
      url: `enrol/course-material/?course=${course}&content=${contentId}`,
      onSuccess: GET_COURSE_MATERIAL_SUCCEEDED,
      onFailure: GET_COURSE_MATERIAL_FAILED,
    },
  };
};

export const getCourseMaterialById = ({ materialId, course }) => {
  return {
    type: GET_COURSE_MATERIAL_BY_ID,
    payload: {
      url: `enrol/course-material/${materialId}?course=${course}`,
      onSuccess: GET_COURSE_MATERIAL_BY_ID_SUCCEEDED,
      onFailure: GET_COURSE_MATERIAL_BY_ID_FAILED,
    },
  };
};

export const AddCourseEnrolContent = (data = {}) => {
  return {
    type: ADD_COURSE_ENROL_CONTENT,
    payload: {
      data,
      url: `enrol/courses/`,
      onSuccess: ADD_COURSE_ENROL_CONTENT_SUCCEEDED,
      onFailure: ADD_COURSE_ENROL_CONTENT_FAILED,
    },
  };
};

export const getCourseVedio = (slug = {}) => {
  return {
    type: GET_COURSE_VIDEO,
    payload: {
      url: `videos/${slug}/`,
      onSuccess: GET_COURSE_VIDEO_SUCCEEDED,
      onFailure: GET_COURSE_VIDEO_FAILED,
    },
  };
};

export const addCourseEnroll = (data = {}) => {
  return {
    type: ADD_COURSE_ENROLL,
    payload: {
      data,
      url: 'enrol/courses/',
      onSuccess: ADD_COURSE_ENROLL_SUCCEEDED,
      onFailure: ADD_COURSE_ENROLL_FAILED,
    },
  };
};

export const updateCourseEnrollDetail = (data = {}, slug = {}) => {
  return {
    type: UPDATE_COURSE_ENROL_DETAIL,
    payload: {
      data,
      url: `enrol/courses/${slug}/`,
      onSuccess: UPDATE_COURSE_ENROL_DETAIL_SUCCEEDED,
      onFailure: UPDATE_COURSE_ENROL_DETAIL_FAILED,
    },
  };
};

export const getCourseEnroll = (course = {}, page = {}, user = '', perPage = 10) => {
  return {
    type: GET_COURSE_ENROLL,
    payload: {
      url: `enrol/courses/?course=${course}&page=${page}&user=${user}&per_page=${perPage}`,
      onSuccess: GET_COURSE_ENROLL_SUCCEEDED,
      onFailure: GET_COURSE_ENROLL_FAILED,
    },
  };
};

// Related Courses
export const getRelatedCourses = (category = null, relatedCourse = false) => {
  return {
    type: GET_RELATED_COURSE,
    payload: {
      url: `course-list/?${
        category
          ? `category=${category}`
          : relatedCourse
          ? `related_course=${relatedCourse}`
          : ''
      }`,
      onSuccess: GET_RELATED_COURSE_SUCCEEDED,
      onFailure: GET_RELATED_COURSE_FAILED,
    },
  };
};

// Notes

export const getNotes = (per_page = {}, courseId = {}, contentId = {}) => {
  return {
    type: GET_NOTES,
    payload: {
      url: `sticky-notes/?per_page=${per_page}&course=${courseId}&content=${contentId}`,
      onSuccess: GET_NOTES_SUCCEEDED,
      onFailure: GET_NOTES_FAILED,
    },
  };
};

export const addNotes = (data = {}) => {
  return {
    type: ADD_NOTES,
    payload: {
      data,
      url: `sticky-notes/`,
      onSuccess: ADD_NOTES_SUCCEEDED,
      onFailure: ADD_NOTES_FAILED,
    },
  };
};
export const editNotes = (data = {}, id = {}) => {
  return {
    type: EDIT_NOTES,
    payload: {
      data,
      url: `sticky-notes/${id}/`,
      onSuccess: EDIT_NOTES_SUCCEEDED,
      onFailure: EDIT_NOTES_FAILED,
    },
  };
};

export const deleteNotes = (id = {}) => {
  return {
    type: DELETE_NOTES,

    payload: {
      url: `sticky-notes/${id}`,
      onSuccess: DELETE_NOTES_SUCCEEDED,
      onFailure: DELETE_NOTES_FAILED,
    },
  };
};

export const getCourseQuestions = ({ data }) => {
  let query = getCustomQueries(data);
  return {
    type: GET_QUESTION_DOUBTS,
    payload: {
      url: `doubts/?${query}`,
      // ?text=${text}&course=${courseId}`,
      onSuccess: GET_QUESTION_DOUBTS_SUCCEEDED,
      onFailure: GET_QUESTION_DOUBTS_FAILED,
    },
  };
};

export const getCourseQuestionsByPage = ({ data }) => {
  let query = getCustomQueries(data);
  return {
    type: GET_QUESTION_DOUBTS_BY_PAGE,
    payload: {
      url: `doubts/?${query}`,
      // ?text=${text}&course=${courseId}`,
      onSuccess: GET_QUESTION_DOUBTS_BY_PAGE_SUCCEEDED,
      onFailure: GET_QUESTION_DOUBTS_BY_PAGE_FAILED,
    },
  };
};

export const getDoubtQuestionDetail = (id = {}) => {
  return {
    type: GET_QUESTION_DOUBTS_DETAIL,
    payload: {
      url: `doubts/${id}`,
      onSuccess: GET_QUESTION_DOUBTS_DETAIL_SUCCEEDED,
      onFailure: GET_QUESTION_DOUBTS_DETAIL_FAILED,
    },
  };
};

export const getDoubtCommentsQuestion = ({ data }) => {
  let query = getCustomQueries(data);
  return {
    type: GET_QUESTION_DOUBT_COMMENT,
    payload: {
      url: `doubt-comments/?${query}`,
      onSuccess: GET_QUESTION_DOUBT_COMMENT_SUCCEEDED,
      onFailure: GET_QUESTION_DOUBT_COMMENT_FAILED,
    },
  };
};

export const getDoubtCommentsQuestionByPage = ({ data }) => {
  let query = getCustomQueries(data);
  return {
    type: GET_QUESTION_DOUBT_COMMENT_BY_PAGE,
    payload: {
      url: `doubt-comments/?${query}`,
      onSuccess: GET_QUESTION_DOUBT_COMMENT_BY_PAGE_SUCCEEDED,
      onFailure: GET_QUESTION_DOUBT_COMMENT_BY_PAGE_FAILED,
    },
  };
};

export const addQuestionDoubtComments = (data = {}) => {
  return {
    type: ADD_QUESTION_DOUBT_COMMENT,
    payload: {
      data,
      url: `doubt-comments/`,
      onSuccess: ADD_QUESTION_DOUBT_COMMENT_SUCCEEDED,
      onFailure: ADD_QUESTION_DOUBT_COMMENT_FAILED,
    },
  };
};

export const deleteQuestionComment = ({ commentId }) => {
  return {
    type: DELETE_QUESTION_DOUBT_COMMENT,
    payload: {
      url: `doubt-comments/${commentId}`,
      onSuccess: DELETE_QUESTION_DOUBT_COMMENT_SUCCEEDED,
      onFailure: DELETE_QUESTION_DOUBT_COMMENT_FAILED,
    },
  };
};

export const editQuestionComments = ({ data, commentId }) => {
  return {
    type: EDIT_QUESTION_DOUBT_COMMENT,
    payload: {
      data,
      url: `doubt-comments/${commentId}/`,
      onSuccess: EDIT_QUESTION_DOUBT_COMMENT_SUCCEEDED,
      onFailure: EDIT_QUESTION_DOUBT_COMMENT_FAILED,
    },
  };
};

export const likeQuestionComment = ({ data }) => {
  return {
    type: LIKE_QUESTION_DOUBT_COMMENT,
    payload: {
      data,
      url: `doubt-comments-like/`,
      onSuccess: LIKE_QUESTION_DOUBT_COMMENT_SUCCEEDED,
      onFailure: LIKE_QUESTION_DOUBT_COMMENT_FAILED,
    },
  };
};

export const addDoubtQuestions = (data = {}) => {
  return {
    type: ADD_QUESTION_DOUBTS,
    payload: {
      data,
      url: 'doubts/',
      onSuccess: ADD_QUESTION_DOUBTS_SUCCEEDED,
      onFailure: ADD_QUESTION_DOUBTS_FAILED,
    },
  };
};

export const deletecourseQuestion = ({ questionId }) => {
  return {
    type: DELETE_QUESTION_DOUBTS,
    payload: {
      url: `doubts/${questionId}`,
      onSuccess: DELETE_QUESTION_DOUBTS_SUCCEEDED,
      onFailure: DELETE_QUESTION_DOUBTS_FAILED,
    },
  };
};

export const editcourseQuestion = ({ data, questionId }) => {
  return {
    type: EDIT_QUESTION_DOUBTS,
    payload: {
      data,
      url: `doubts/${questionId}/`,
      onSuccess: EDIT_QUESTION_DOUBTS_SUCCEEDED,
      onFailure: EDIT_QUESTION_DOUBTS_FAILED,
    },
  };
};

export const likeCourseQuestion = (doubt) => {
  return {
    type: LIKE_COURSE_QUESTION,
    payload: {
      url: `doubt-like/?doubt=${doubt}`,
      onSuccess: LIKE_COURSE_QUESTION_SUCCEEDED,
      onFailure: LIKE_COURSE_QUESTION_FAILED,
    },
  };
};

export const setCourseData = (data = {}) => {
  return {
    type: SET_COURSE_DATA,
    payload: {
      data,
    },
  };
};

export const setCourseEnrollEditData = (data = {}) => {
  return {
    type: SET_COURSE_ENROLL_EDIT_DATA,
    payload: {
      data,
    },
  };
};
export const setCourseEditData = (payload) => {
  return {
    type: SET_COURSE_EDIT_DATA,
    payload,
  };
};

export const setCourseErrorData = (data = {}) => {
  return {
    type: SET_COURSE_ERROR_DATA,
    payload: {
      data,
    },
  };
};

export const clearCourseData = () => {
  return {
    type: CLEAR_COURSE_DATA,
  };
};

// Course Review

export const getCourseReview = (course = {}, per_page = {}) => {
  return {
    type: GET_COURSE_REVIEW,
    payload: {
      url: `course-review/?course=${course}&per_page=${per_page}`,
      onSuccess: GET_COURSE_REVIEW_SUCCEEDED,
      onFailure: GET_COURSE_REVIEW_FAILED,
    },
  };
};

export const getCourseReviewById = (id = {}) => {
  return {
    type: GET_COURSE_REVIEW,
    payload: {
      url: `course-review/${id}/`,
      onSuccess: GET_COURSE_REVIEW_SUCCEEDED,
      onFailure: GET_COURSE_REVIEW_FAILED,
    },
  };
};

export const getCourseReviewByPage = (course = {}, page = {}, per_page = {}) => {
  return {
    type: GET_COURSE_REVIEW_BY_PAGE,
    payload: {
      url: `course-review/?course=${course}&page=${page}&per_page=${per_page}`,
      onSuccess: GET_COURSE_REVIEW_BY_PAGE_SUCCEEDED,
      onFailure: GET_COURSE_REVIEW_BY_PAGE_FAILED,
    },
  };
};

export const likeCourseReview = (review) => {
  return {
    type: LIKE_COURSE_REVIEW,
    payload: {
      url: `review-like/?review=${review}`,
      onSuccess: LIKE_COURSE_REVIEW_SUCCEEDED,
      onFailure: LIKE_COURSE_REVIEW_FAILED,
    },
  };
};

export const dislikeCourseReview = (review) => {
  return {
    type: DISLIKE_COURSE_REVIEW,
    payload: {
      url: `review-dislike/?review=${review}`,
      onSuccess: DISLIKE_COURSE_REVIEW_SUCCEEDED,
      onFailure: DISLIKE_COURSE_REVIEW_FAILED,
    },
  };
};

export const addCourseReview = (data = {}) => {
  return {
    type: ADD_COURSE_REVIEW,
    payload: {
      data,
      url: 'course-review/',
      onSuccess: ADD_COURSE_REVIEW_SUCCEEDED,
      onFailure: ADD_COURSE_REVIEW_FAILED,
    },
  };
};

export const editCourseReview = (data = {}, id = {}) => {
  return {
    type: EDIT_COURSE_REVIEW,
    payload: {
      data,
      url: `course-review/${id}/`,
      onSuccess: EDIT_COURSE_REVIEW_SUCCEEDED,
      onFailure: EDIT_COURSE_REVIEW_FAILED,
    },
  };
};

export const deleteCourseReview = (id = {}) => {
  return {
    type: DELETE_COURSE_REVIEW,
    payload: {
      url: `course-review/${id}/`,
      onSuccess: DELETE_COURSE_REVIEW_SUCCEEDED,
      onFailure: DELETE_COURSE_REVIEW_FAILED,
    },
  };
};

// Admin Dashboard view Request Course Form
export const requestedCourseEnquiry = (page = {}, per_page = {}) => {
  return {
    type: GET_REQUESTED_COURSE_INQUIRY,
    payload: {
      url: `requested-course-enquiry/?page=${page}&per_page=${per_page}`,
      onSuccess: GET_REQUESTED_COURSE_INQUIRY_SUCCEEDED,
      onFailure: GET_REQUESTED_COURSE_INQUIRY_FAILED,
    },
  };
};

// Admin Dashboard view Request Course Edit status
export const editCourseRequest = (data = {}, id: number) => {
  return {
    type: EDIT_REQUESTED_COURSE_INQUIRY_STATUS,
    payload: {
      data,
      url: `update-course-enquiry-request/${id}/`,
      onSuccess: EDIT_REQUESTED_COURSE_INQUIRY_STATUS_SUCCEEDED,
      onFailure: EDIT_REQUESTED_COURSE_INQUIRY_STATUS_FAILED,
    },
  };
};

export const getCourseClient = (data = {}) => {
  let query = '';
  if (data) {
    Object.keys(data).map((each) => {
      query = `${query}&${each}=${data[each]}`;
    });
  }
  return {
    type: GET_COURSE_CLIENT,
    payload: {
      url: `enrol/course-client/?${query}`,
      onSuccess: GET_COURSE_CLIENT_SUCCEEDED,
      onFailure: GET_COURSE_CLIENT_FAILED,
    },
  };
};

export const getMockTestClient = (data = {}) => {
  let query = '';
  if (data) {
    Object.keys(data).map((each) => {
      query = `${query}&${each}=${data[each]}`;
    });
  }
  return {
    type: GET_MOCK_TEST_CLIENT,
    payload: {
      url: `enrol/mock-test-client/?${query}`,
      onSuccess: GET_MOCK_TEST_CLIENT_SUCCEEDED,
      onFailure: GET_MOCK_TEST_CLIENT_FAILED,
    },
  };
};

export const getCourseDoubts = (data = {}) => {
  let query = '';
  if (data) {
    Object.keys(data).map((each) => {
      query = `${query}&${each}=${data[each]}`;
    });
  }

  return {
    type: GET_COURSE_DOUBTS,
    payload: {
      url: `course/doubt/?${query}`,
      onSuccess: GET_COURSE_DOUBTS_SUCCEEDED,
      onFailure: GET_COURSE_DOUBTS_FAILED,
    },
  };
};

export const getCourseDoubtComments = (data = {}) => {
  let query = '';
  if (data) {
    Object.keys(data).map((each) => {
      query = `${query}&${each}=${data[each]}`;
    });
  }
  return {
    type: GET_COURSE_DOUBT_COMMENTS,
    payload: {
      url: `course/doubt/comment/?${query}`,
      onSuccess: GET_COURSE_DOUBT_COMMENTS_SUCCEEDED,
      onFailure: GET_COURSE_DOUBT_COMMENTS_FAILED,
    },
  };
};

export const addCourseDoubtComment = (data = {}) => {
  return {
    type: ADD_COURSE_DOUBT_COMMENTS,
    payload: {
      data,
      url: 'course/doubt/comment/',
      onSuccess: ADD_COURSE_DOUBT_COMMENTS_SUCCEEDED,
      onFailure: ADD_COURSE_DOUBT_COMMENTS_FAILED,
    },
  };
};

export const editCourseDoubtComment = (data = {}, commentId = {}) => {
  return {
    type: EDIT_COURSE_DOUBT_COMMENTS,
    payload: {
      data,
      url: `course/doubt/comment/${commentId}/`,
      onSuccess: EDIT_COURSE_DOUBT_COMMENTS_SUCCEEDED,
      onFailure: EDIT_COURSE_DOUBT_COMMENTS_FAILED,
    },
  };
};

export const deleteCourseDoubtComment = (commentId = {}) => {
  return {
    type: DELETE_COURSE_DOUBT_COMMENTS,
    payload: {
      url: `course/doubt/comment/${commentId}/`,
      onSuccess: DELETE_COURSE_DOUBT_COMMENTS_SUCCEEDED,
      onFailure: DELETE_COURSE_DOUBT_COMMENTS_FAILED,
    },
  };
};

export const getCourseClients = (data = {}) => {
  let query = '';
  if (data) {
    Object.keys(data).map((each) => {
      query = `${query}&${each}=${data[each]}`;
    });
  }
  return {
    type: GET_COURSE_CLIENTS,
    payload: {
      url: `enrol/course-client/?${query}`,
      onSuccess: GET_COURSE_CLIENTS_SUCCEEDED,
      onFailure: GET_COURSE_CLIENTS_FAILED,
    },
  };
};

export const addCourseClientRequest = ({ data }) => {
  return {
    type: ADD_COURSE_CLIENTS,
    payload: {
      data,
      url: `enrol/course-client/`,
      onSuccess: ADD_COURSE_CLIENTS_SUCCEEDED,
      onFailure: ADD_COURSE_CLIENTS_FAILED,
    },
  };
};

export const updateCourseClientRequest = ({ data, enrolId }) => {
  return {
    type: EDIT_COURSE_CLIENTS,
    payload: {
      data,
      url: `enrol/course-client/${enrolId}/`,
      onSuccess: EDIT_COURSE_CLIENTS_SUCCEEDED,
      onFailure: EDIT_COURSE_CLIENTS_FAILED,
    },
  };
};

export const getCourseFeedbacks = (data = {}) => {
  let query = '';
  if (data) {
    Object.keys(data).map((each) => {
      query = `${query}&${each}=${data[each]}`;
    });
  }
  return {
    type: GET_COURSE_FEEDBACKS,
    payload: {
      url: `course/feedback/?${query}`,
      onSuccess: GET_COURSE_FEEDBACKS_SUCCEEDED,
      onFailure: GET_COURSE_FEEDBACKS_FAILED,
    },
  };
};

export const getCourseFeedbackById = ({ feedbackId }) => {
  return {
    type: GET_COURSE_FEEDBACK_BY_ID,
    payload: {
      url: `course/feedback/${feedbackId}`,
      onSuccess: GET_COURSE_FEEDBACK_BY_ID_SUCCEEDED,
      onFailure: GET_COURSE_FEEDBACK_BY_ID_FAILED,
    },
  };
};

export const getCourseLiveClass = (data = {}) => {
  let query = '';
  if (data) {
    Object.keys(data).map((each) => {
      query = `${query}&${each}=${data[each]}`;
    });
  }
  return {
    type: GET_COURSE_LIVE_CLASS,
    payload: {
      url: `course/class/?${query}`,
      onSuccess: GET_COURSE_LIVE_CLASS_SUCCEEDED,
      onFailure: GET_COURSE_LIVE_CLASS_FAILED,
    },
  };
};

export const addCourseLiveClass = (data = {}) => {
  return {
    type: ADD_COURSE_LIVE_CLASS,
    payload: {
      data,
      url: `course/class/`,
      onSuccess: ADD_COURSE_LIVE_CLASS_SUCCEEDED,
      onFailure: ADD_COURSE_LIVE_CLASS_FAILED,
    },
  };
};
export const editCourseLiveClass = (data = {}, id = {}) => {
  return {
    type: EDIT_COURSE_LIVE_CLASS,
    payload: {
      data,
      url: `course/class/${id}/`,
      onSuccess: EDIT_COURSE_LIVE_CLASS_SUCCEEDED,
      onFailure: EDIT_COURSE_LIVE_CLASS_FAILED,
    },
  };
};

export const deleteCourseLiveClass = (id = {}) => {
  return {
    type: DELETE_COURSE_LIVE_CLASS,

    payload: {
      url: `course/class/${id}/`,
      onSuccess: DELETE_COURSE_LIVE_CLASS_SUCCEEDED,
      onFailure: DELETE_COURSE_LIVE_CLASS_FAILED,
    },
  };
};
export const clearCourseError = () => {
  return {
    type: CLEAR_COURSE_ERROR,
  };
};

export const setActiveSubjectId = (payload) => {
  return {
    type: ACTIVE_SUBJECT_ID,
    payload,
  };
};

export const setCourseQueryData = (payload) => {
  return {
    type: SET_COURSE_QUERY_DATA,
    payload,
  };
};

export const clearCourseQuery = () => {
  return {
    type: CLEAR_COURSE_QUERY,
  };
};

export const getCourseInquiryComments = (data = {}) => {
  let query = '';
  if (data) {
    Object.keys(data).map((each) => {
      query = `${query}&${each}=${data[each]}`;
    });
  }
  return {
    type: GET_COURSE_COMMENTS_REQUEST,
    payload: {
      url: `request-course-comment/?${query}`,
      onSuccess: GET_COURSE_COMMENTS_SUCCEEDED,
      onFailure: GET_COURSE_COMMENTS_FAILED,
    },
  };
};
export const addCourseInquiryComments = (data = {}) => {
  return {
    type: ADD_COURSE_COMMENTS_REQUEST,
    payload: {
      data,
      url: `request-course-comment/`,
      onSuccess: ADD_COURSE_COMMENTS_SUCCEEDED,
      onFailure: ADD_COURSE_COMMENTS_FAILED,
    },
  };
};
export const editCourseInquiryComments = (data = {}, id: number) => {
  return {
    type: EDIT_COURSE_COMMENTS_REQUEST,
    payload: {
      data,
      url: `request-course-comment/${id}/`,
      onSuccess: EDIT_COURSE_COMMENTS_SUCCEEDED,
      onFailure: EDIT_COURSE_COMMENTS_FAILED,
    },
  };
};
export const deleteCourseInquiryComments = (id: number) => {
  return {
    type: DELETE_COURSE_COMMENTS_REQUEST,
    payload: {
      url: `request-course-comment/${id}/`,
      onSuccess: DELETE_COURSE_COMMENTS_SUCCEEDED,
      onFailure: DELETE_COURSE_COMMENTS_FAILED,
    },
  };
};

export const getFreeTrialUsers = ({ data }) => {
  let query = '';
  if (data) {
    Object.keys(data).map((each) => {
      query =
        data[each] === undefined || data[each] === null || data[each] === ''
          ? `${query}`
          : `${query}&${each}=${data[each]}`;
    });
  }
  return {
    type: GET_COURSE_FREE_TRIALS,
    payload: {
      url: `enrol/course-trials?${query}`,
      onSuccess: GET_COURSE_FREE_TRIALS_SUCCEEDED,
      onFailure: GET_COURSE_FREE_TRIALS_FAILED,
    },
  };
};
